// 
// user.scss
// Use this to write your custom SCSS
//

body{
  color: $color-black-90;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1{
  margin-bottom: 1rem;
  margin-top: 1rem;  
}

.container-full{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: inherit;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1280px){
    max-width: 1750px;
  }
}

.bg-main-alpha{
  background-color: $color-main-alpha;
}

.bg-main{
  background-color: $color-main !important;
}

.bg-gray{
  background-color: $gray-400;
}

.bg-light-t{
  background-color: #E5E5E5;
}
.sec-color{
  color: $color-sec !important;
}
.blk-color{
  color: $black;
}
.blk-color-90{
  color: $color-black-90;
}
.main-color{
  color: $color-main !important; 
}

.navbar-expand-lg {
  .navbar-nav{
    .nav-link{
      @media (min-width: 992px) and (max-width: 1024px){
        padding-right: 0.8rem;
        padding-left: 0.8rem;
      }
    }
    .dropdown .dropdown-toggle {  
      &::after {
        vertical-align: unset;        
      }
    }



  }
  .dropdown-menu{
    background-color: $color-main;    
    border-color: transparent;
    border-radius: unset;
    border-top: 1px solid;
    // top: 75px;
    &:before {
      content: '';
      position: absolute;
      background: #0058a4;
      border: 1px solid;
      width: 15px;
      height: 15px;
      z-index: 1;
      border-radius: 25px 25px 0 0;
      top: -14px;
      left: 50%;
      margin-left: -25px;
      border-bottom: unset;
    }

    .dropdown-item{
      color: $white;
      padding: 0.75rem 1.5rem;

      &:hover { 
        color: $color-sec; 
      }      

    }
  }

  .navbar-tool{
    @media (min-width: 992px){
      margin-left: 1rem;
    }
  }

  .navbar-tool-icon{
    @media (min-width: 992px) and (min-width: 1020px){
      font-size: 1.3rem;
  
    }
  }
}


.nav-link{

  color: $white;
  letter-spacing: 1.5px;

  &.active{
    color: $white;    
  }  
  
}

.navbar-nav{
  .nav-link{
    &:hover,
    &:focus { 
      color: $color-sec; 
      &:before {
        content : "";
        position: relative;
        left: -10px;
        bottom: 0;
        height  : 20%;
        width   : 1px;
        border-left:1px solid $color-sec;
      }
    }
  }
}


.navbar-sticky {
  &.navbar-stuck {
    background-color: $color-main-alpha;
  }
}
// toggler
.navbar-toggler-icon {
  &,
  &::before,
  &::after {
    background-color: $white;
  }
}
.bgtitle{
  
  position: relative;

  &::before{
    content: ' ';
    width: 4px;
    height: 12%;
    position: absolute;
    left: 15px;
    top: 17px;
    z-index: 3;
    background-color: $color-sec;
  }
}


.bgtitle-slider{
  position: relative;
  &::before{
    content: ' ';
    width: 4px;
    height: 12%;
    position: absolute;
    left: 15px;
    top: 20px;
    z-index: 3;
    background-color: $color-sec;
  }
}

.bgtitle-head{  
  &::before{
    content: ' ';
    width:175px;
    height:65px;    
    background: linear-gradient(to right, $color-main 80%, $color-main-overlay 20%);
    position: absolute;        
    z-index: 10;    
  }
}
.bgtitle-head-margin{
  margin-left: 160px;
  transform: translateY(10px);
  padding-bottom: 0.45rem;
}

.bg-light{
background-color: $gray-100 !important;
}

.btn-scroll-top {
  background-color: $color-main; 
}

.transform-40d{
  transform: translateY(-40px);
}

.transform-120d{
  transform: translateY(-120px);
}



/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.min-height-280{
  min-height: 280px;
}

.min-height-250{
  min-height: 250px;
}

.min-height-220{
  min-height: 220px;
}

.min-height-200{
  min-height: 200px;
}
.min-height-150{
  min-height: 150px;
}

.max-height-150{
  max-height: 150px;
}

.max-height-350{
  max-height: 350px;
}
.obj-contain{
  object-fit: contain;
}
.obj-cover{
  object-fit: cover;
}




.video-banner-icon-play {
  position: absolute;
  left: 50%;
  top: 51.5%;
  
  display: block;
  font-size: 7rem;
  color: $white;
  
  -webkit-transform: translateX(-50%) translateY(-50%) scale(1.0);
	-moz-transform: translateX(-50%) translateY(-50%) scale(1.0);
	-ms-transform: translateX(-50%) translateY(-50%) scale(1.0);
	-o-transform: translateX(-50%) translateY(-50%) scale(1.0);
	transform: translateX(-50%) translateY(-50%) scale(1.0);
  
  -webkit-transition: all 0.2s ease-out 0.05s;
	transition: all 0.2s ease-out 0.05s;


    &:hover {
      -webkit-transform: translateX(-50%) translateY(-50%) scale(1.2);
      -moz-transform: translateX(-50%) translateY(-50%) scale(1.2);
      -ms-transform: translateX(-50%) translateY(-50%) scale(1.2);
      -o-transform: translateX(-50%) translateY(-50%) scale(1.2);
      transform: translateX(-50%) translateY(-50%) scale(1.2);
      
      color: $color-main;
    }
}


.nav-fill,
.nav-justified {
  .nav-item .nav-link {
    font-size: .95rem;
  }
}



.btn-outline-primary{
  color: $color-main;
  border-color: $color-main;

  &:hover {
    background-color: $color-main;
    border-color: $color-main;
  }
}


.navbar-expand-lg{
  .navbar-tool-icon{
    color: $white;
  }
} 

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1{
  color: $color-main;
}

.portfolio-card-title{
  color: $color-main;
}

.accordion-button {
  &:not(.collapsed) {
    color: $color-main;
  }
}

.form-check-input {
  &:checked{
    background-color: $color-main;
    border-color: $color-main;
  }
}

.text-muted{
  color: $color-black-90 !important;
}


.nav-tabs{
  .nav-link{
    color: $color-black-90;

    &:hover{
      color: $color-main;
    }
  }
}

.grid-item{
  .card{
    .card-footer{
      padding-left: 4.5rem;
      padding-right: 4.5rem;
    }
  }
}



/* ======= Floating Contact Us ======= */


.ba-we-love-subscribers {
  width: 250px;
  height: 30px;
  background-color: $color-main;
  border-radius: 5px 5px 0 0;    
  text-align: center;  
  overflow: hidden;
  color: #fff;
  opacity: 0;
  padding: 10px;

  &.open {
    height: 220px;
    opacity: 1;
  }

  &.popup-ani {
    -webkit-transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

}


.ba-we-love-subscribers-fab {
  width: 250px;
  height: 30px;
  background-color: $color-main;  
  border-radius: 5px 5px 0 0;
  float: right;  
  z-index: 5;
  position: fixed;
  padding: 3px 0;
  bottom: 0;
  right: 20px;



  .ani {
    .ci-angle-up{
      &::before{
        content: $ci-angle-down;
      }
      
    }
    
  }

  .close {
    background-position: -2px 1px;
    transform: rotate(-45deg);
    float: none;

    /*Bootstrap Overide*/
    opacity: 1;

    /*Bootstrap Overide*/
  }
}

.ba-we-love-subscribers-wrap {
  position: fixed;
  right: 20px;
  bottom: 25px;
  z-index: 1000;
}

.ba-settings {
  position: absolute;
  top: -25px;
  right: 0px;
  padding: 10px 20px;
  background-color: #555;
  border-radius: 5px;
  color: #fff;
}

/* ======= END Floating Contact Us ======= */

.row{
  .divider-vertical{
    &:last-child{
      display: none !important;
    }
  }
}

.solutionList{
  ul{
    list-style-type: none;
    li{
      // padding-left: 10px;
      // text-indent: -25px;

      &:before {
        content: "\e91b";
        font-family: "createx-icons";        
        margin-left: -25px;
        margin-top: 3px;
        position: absolute;
      }
    }
  }
}

.m-logo{
  width: 185px;
  @media screen and (max-width: 992px){
    width: 160px;
  }
}

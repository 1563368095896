// 
// user-variables.scss
// Use this to override Bootstrap and variables
//


@font-face {
  font-family: 'Frutiger';
  font-style: normal;
  font-weight: normal;
  src: local('Frutiger'), url('../fonts/frutiger/Frutiger.woff') format('woff');
}


@font-face {
  font-family: 'Frutiger Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Frutiger Bold'), url('../fonts/frutiger/Frutiger_bold.woff') format('woff');
}

@font-face {
  font-family: 'Frutiger Light';
  src: url('../fonts/frutiger/Frutiger-Light.woff2') format('woff2'),
      url('../fonts/frutiger/Frutiger-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova Rg';
  src: url('../fonts/ProximaNova-Regular/ProximaNova-Regular.eot');
  src: url('../fonts/ProximaNova-Regular/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/ProximaNova-Regular/ProximaNova-Regular.woff2') format('woff2'),
      url('../fonts/ProximaNova-Regular/ProximaNova-Regular.woff') format('woff'),
      url('../fonts/ProximaNova-Regular/ProximaNova-Regular.ttf') format('truetype'),
      url('../fonts/ProximaNova-Regular/ProximaNova-Regular.svg#ProximaNova-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}





$color-main: #0058A4;
$color-main-overlay: rgba($color-main, .3);
$color-main-alpha: rgb(5 77 140 / 95%);
$color-sec: #F47920;
$color-black-90: #414042;

$font-family-sans-serif: 'Frutiger Light' !default;
$font-family-proxima: 'Proxima' !default;

//
// Search-bar
// --------------------------------------------------

#search {
  align-items: center;
  background: rgba(0, 0, 0, 0.975);
  height: 0;
  display: flex;
  justify-content: center;
  opacity: 0;
  position: fixed;
  transition: all 0.5s;
  width: 100vw;
  will-change: transform, opacity;
  z-index: -1;

  &:target {
    height: 100vh !important;
    opacity: 1;
    width: 100vw !important;
    z-index: 1040;
    top: 0;

    .close-btn {
      display: block;
      z-index: 1050;
    }
  }
}

#searchbox {
  background: transparent;
  border: solid #fff;
  border-width: 0 0 1px 0;
  color: #fff;
  flex: 1 0 auto;
  font-size: 2rem;
  height: 2rem;
  max-width: 50%;
  outline: 0;
  padding: 2rem 1rem;
}

.close-btn {
  display: none;
  color: #fff;
  font-size: 2rem;
  position: absolute;
  top: 0.5rem;
  right: 2rem;
  display: block;
  position: fixed;
  @media screen and (min-width: 768px) {    
      display: none;    
  }
  
}



#search.fade {
  height: 100vh;
  width: 100vw;
  transition: opacity 0.5s;
}